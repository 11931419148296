export const accordionTags = [
  { sku: 'react-accordion', text: 'React Accordion' },
  { sku: 'react-collapsible-component', text: 'React Collapsible Component' },
  { sku: 'react-ui-accordion', text: 'React UI Accordion' },
  { sku: 'react-toggle-panel', text: 'React Toggle Panel' },
  { sku: 'react-dropdown', text: 'React Dropdown' },
  { sku: 'react-expandable-list', text: 'React Expandable List' },
  { sku: 'react-accordion-menu', text: 'React Accordion Menu' },
  { sku: 'accordion-component-react', text: 'Accordion Component for React' },
  { sku: 'collapsible-ui-react', text: 'Collapsible UI in React' },
  { sku: 'react-accordion-plugin', text: 'React Accordion Plugin' },
  { sku: 'react-custom-accordion', text: 'Custom Accordion in React' },
  { sku: 'accordion-animation-react', text: 'Accordion Animation in React' },
  { sku: 'react-accordion-hooks', text: 'React Accordion with Hooks' },
  { sku: 'react-ui-library-accordion', text: 'React UI Library Accordion' },
  { sku: 'react-accordion-example', text: 'Example Accordion in React' },
  { sku: 'react-accordion-styling', text: 'Styling React Accordion' },
  { sku: 'react-accordion-component', text: 'React Accordion Component' },
  { sku: 'dynamic-react-accordion', text: 'Dynamic React Accordion' },
  { sku: 'react-accordion-accessibility', text: 'React Accordion Accessibility' },
  { sku: 'react-multi-accordion', text: 'React Multi-Level Accordion' }
];

export const alertTags = [
  { sku: 'react-alert', text: 'React Alert' },
  { sku: 'react-alert-component', text: 'React Alert Component' },
  { sku: 'react-toast-notification', text: 'React Toast Notification' },
  { sku: 'react-alert-message', text: 'React Alert Message' },
  { sku: 'react-popup-alert', text: 'React Popup Alert' },
  { sku: 'react-ui-alert', text: 'React UI Alert' },
  { sku: 'react-dismissible-alert', text: 'React Dismissible Alert' },
  { sku: 'react-custom-alert', text: 'Custom Alert in React' },
  { sku: 'react-alert-hooks', text: 'React Alert with Hooks' },
  { sku: 'react-toast-component', text: 'React Toast Component' },
  { sku: 'react-alert-example', text: 'Example React Alert' },
  { sku: 'react-notification-system', text: 'React Notification System' },
  { sku: 'react-banner-alert', text: 'React Banner Alert' },
  { sku: 'react-toast-alert', text: 'React Toast Alert' },
  { sku: 'react-modal-alert', text: 'React Modal Alert' },
  { sku: 'react-error-alert', text: 'React Error Alert' },
  { sku: 'react-success-alert', text: 'React Success Alert' },
  { sku: 'react-alert-styling', text: 'Styling React Alert' },
  { sku: 'react-alert-animation', text: 'React Alert Animation' },
  { sku: 'react-alert-accessibility', text: 'React Alert Accessibility' }
];

export const badgeTags = [
  { sku: 'react-badge', text: 'React Badge' },
  { sku: 'react-notification-badge', text: 'React Notification Badge' },
  { sku: 'react-status-badge', text: 'React Status Badge' },
  { sku: 'react-badge-component', text: 'React Badge Component' },
  { sku: 'react-badge-with-icon', text: 'React Badge with Icon' },
  { sku: 'react-badge-styling', text: 'Styling React Badge' },
  { sku: 'react-badge-examples', text: 'React Badge Examples' },
  { sku: 'react-badge-list', text: 'React Badge List' },
  { sku: 'react-custom-badge', text: 'Custom Badge in React' },
  { sku: 'react-badge-counter', text: 'React Badge Counter' },
  { sku: 'react-badge-animation', text: 'Badge Animation in React' },
  { sku: 'react-badge-hooks', text: 'React Badge with Hooks' },
  { sku: 'react-ui-library-badge', text: 'React UI Library Badge' },
  { sku: 'badge-component-react', text: 'Badge Component for React' },
  { sku: 'react-badge-notification', text: 'React Badge Notification' },
  { sku: 'dynamic-react-badge', text: 'Dynamic React Badge' },
  { sku: 'react-badge-accessibility', text: 'React Badge Accessibility' },
  { sku: 'react-badge-tooltip', text: 'React Badge with Tooltip' },
  { sku: 'react-badge-design', text: 'React Badge Design' },
  { sku: 'responsive-react-badge', text: 'Responsive React Badge' }
];

export const buttonTags = [
  { sku: 'react-button', text: 'React Button' },
  { sku: 'react-custom-button', text: 'Custom Button in React' },
  { sku: 'react-styled-button', text: 'Styled Button Component' },
  { sku: 'react-icon-button', text: 'React Icon Button' },
  { sku: 'react-loading-button', text: 'Loading Button in React' },
  { sku: 'react-button-component', text: 'React Button Component' },
  { sku: 'react-button-group', text: 'React Button Group' },
  { sku: 'react-button-with-icon', text: 'Button with Icon in React' },
  { sku: 'react-disabled-button', text: 'Disabled Button in React' },
  { sku: 'react-button-styles', text: 'Button Styles in React' },
  { sku: 'react-button-example', text: 'Example Button in React' },
  { sku: 'react-rounded-button', text: 'Rounded Button in React' },
  { sku: 'react-button-with-tooltip', text: 'Button with Tooltip in React' },
  { sku: 'react-button-animation', text: 'Button Animation in React' },
  { sku: 'react-button-onclick', text: 'React Button OnClick Handler' },
  { sku: 'react-submit-button', text: 'Submit Button in React' },
  { sku: 'react-primary-button', text: 'Primary Button in React' },
  { sku: 'react-secondary-button', text: 'Secondary Button in React' },
  { sku: 'react-button-accessibility', text: 'Button Accessibility in React' },
  { sku: 'react-button-with-loading', text: 'Button with Loading Indicator' }
];

export const buttonGroupTags = [
  { sku: 'react-button-group', text: 'React Button Group' },
  { sku: 'react-button-toolbar', text: 'React Button Toolbar' },
  { sku: 'react-toggle-buttons', text: 'React Toggle Buttons' },
  { sku: 'react-button-group-component', text: 'React Button Group Component' },
  { sku: 'react-custom-button-group', text: 'Custom Button Group in React' },
  { sku: 'react-button-group-example', text: 'Example React Button Group' },
  { sku: 'react-button-group-styling', text: 'Styling React Button Group' },
  { sku: 'react-button-group-hooks', text: 'React Button Group with Hooks' },
  { sku: 'react-button-group-accessibility', text: 'React Button Group Accessibility' },
  { sku: 'react-multi-button-group', text: 'React Multi-Button Group' },
  { sku: 'responsive-react-button-group', text: 'Responsive React Button Group' },
  { sku: 'react-icon-button-group', text: 'React Icon Button Group' },
  { sku: 'react-button-group-layout', text: 'React Button Group Layout' },
  { sku: 'react-button-group-with-dropdown', text: 'Button Group with Dropdown in React' },
  { sku: 'react-button-group-usage', text: 'React Button Group Usage' },
  { sku: 'react-button-group-library', text: 'React Button Group Library' },
  { sku: 'dynamic-react-button-group', text: 'Dynamic React Button Group' },
  { sku: 'react-button-group-variants', text: 'React Button Group Variants' },
  { sku: 'react-button-group-interaction', text: 'Button Group Interaction in React' },
  { sku: 'react-button-group-best-practices', text: 'Best Practices for React Button Groups' }
];

export const cardTags = [
  { sku: 'react-card', text: 'React Card' },
  { sku: 'react-card-component', text: 'React Card Component' },
  { sku: 'react-ui-card', text: 'React UI Card' },
  { sku: 'react-card-example', text: 'Example React Card' },
  { sku: 'react-card-design', text: 'React Card Design' },
  { sku: 'react-responsive-card', text: 'Responsive React Card' },
  { sku: 'react-card-styling', text: 'Styling React Card' },
  { sku: 'react-card-layout', text: 'React Card Layout' },
  { sku: 'react-card-animation', text: 'Card Animation in React' },
  { sku: 'react-image-card', text: 'React Image Card' },
  { sku: 'react-card-header', text: 'React Card Header' },
  { sku: 'react-card-footer', text: 'React Card Footer' },
  { sku: 'react-card-list', text: 'React Card List' },
  { sku: 'react-card-interaction', text: 'React Card Interaction' },
  { sku: 'react-flip-card', text: 'React Flip Card' },
  { sku: 'react-elevated-card', text: 'Elevated Card in React' },
  { sku: 'react-card-group', text: 'React Card Group' },
  { sku: 'react-custom-card', text: 'Custom Card in React' },
  { sku: 'react-card-with-image', text: 'React Card with Image' },
  { sku: 'react-card-component-library', text: 'React Card Component Library' }
];

export const checkboxTags = [
  { sku: 'react-checkbox', text: 'React Checkbox' },
  { sku: 'react-checkbox-component', text: 'React Checkbox Component' },
  { sku: 'react-custom-checkbox', text: 'Custom Checkbox in React' },
  { sku: 'react-checkbox-group', text: 'React Checkbox Group' },
  { sku: 'react-checkbox-example', text: 'Example Checkbox in React' },
  { sku: 'react-checkbox-with-label', text: 'React Checkbox with Label' },
  { sku: 'react-checkbox-hooks', text: 'React Checkbox with Hooks' },
  { sku: 'styled-react-checkbox', text: 'Styled React Checkbox' },
  { sku: 'react-accessible-checkbox', text: 'Accessible Checkbox in React' },
  { sku: 'react-multi-checkbox', text: 'Multi-Checkbox Component in React' },
  { sku: 'react-checkbox-validation', text: 'Checkbox Validation in React' },
  { sku: 'react-checkbox-onchange', text: 'Checkbox OnChange Event in React' },
  { sku: 'react-checkbox-styling', text: 'Styling Checkboxes in React' },
  { sku: 'react-checkbox-animation', text: 'Checkbox Animation in React' },
  { sku: 'react-checkbox-api', text: 'React Checkbox API' },
  { sku: 'react-checkbox-library', text: 'React Checkbox Library' },
  { sku: 'react-controlled-checkbox', text: 'Controlled Checkbox in React' },
  { sku: 'react-checkbox-with-tooltip', text: 'Checkbox with Tooltip in React' },
  { sku: 'react-checkbox-using-typescript', text: 'Checkbox in React with TypeScript' },
  { sku: 'react-checkbox-ui', text: 'React UI Checkbox' },
  { sku: 'react-checkbox-design', text: 'Checkbox Design in React' }
];

export const confirmAlertTags = [
  { sku: 'react-confirmation-alert', text: 'React Confirmation Alert' },
  { sku: 'react-alert-dialog', text: 'React Alert Dialog' },
  { sku: 'react-confirmation-modal', text: 'React Confirmation Modal' },
  { sku: 'react-warning-alert', text: 'React Warning Alert' },
  { sku: 'react-confirmation-popup', text: 'React Confirmation Popup' },
  { sku: 'react-alert-component', text: 'React Alert Component' },
  { sku: 'react-modal-confirmation', text: 'React Modal Confirmation' },
  { sku: 'react-alert-notification', text: 'React Alert Notification' },
  { sku: 'react-confirmation-message', text: 'React Confirmation Message' },
  { sku: 'react-user-confirmation', text: 'React User Confirmation' },
  { sku: 'react-alert-system', text: 'React Alert System' },
  { sku: 'react-confirmation-dialog', text: 'React Confirmation Dialog' },
  { sku: 'custom-react-alert', text: 'Custom React Alert' },
  { sku: 'react-prompt-confirmation', text: 'React Prompt Confirmation' },
  { sku: 'react-alert-box', text: 'React Alert Box' },
  { sku: 'react-confirmation-box', text: 'React Confirmation Box' },
  { sku: 'react-alert-handler', text: 'React Alert Handler' },
  { sku: 'react-confirmation-ui', text: 'React Confirmation UI' },
  { sku: 'react-confirmation-service', text: 'React Confirmation Service' },
  { sku: 'react-alert-user-interface', text: 'React Alert User Interface' }
];

export const dialogTags = [
  { sku: 'react-dialog', text: 'React Dialog' },
  { sku: 'react-modal', text: 'React Modal' },
  { sku: 'react-popup', text: 'React Popup' },
  { sku: 'react-modal-component', text: 'React Modal Component' },
  { sku: 'react-dialog-component', text: 'React Dialog Component' },
  { sku: 'react-custom-modal', text: 'Custom Modal in React' },
  { sku: 'responsive-react-modal', text: 'Responsive React Modal' },
  { sku: 'react-modal-with-hooks', text: 'React Modal with Hooks' },
  { sku: 'react-dialog-example', text: 'Example React Dialog' },
  { sku: 'react-modal-styling', text: 'Styling React Modal' },
  { sku: 'accessible-react-modal', text: 'Accessible React Modal' },
  { sku: 'react-confirm-dialog', text: 'React Confirm Dialog' },
  { sku: 'react-modal-animation', text: 'Modal Animation in React' },
  { sku: 'modal-overlay-react', text: 'Modal Overlay in React' },
  { sku: 'react-modal-library', text: 'React Modal Library' },
  { sku: 'react-dialog-with-form', text: 'Dialog with Form in React' },
  { sku: 'react-simple-modal', text: 'Simple React Modal' },
  { sku: 'react-modal-usage', text: 'React Modal Usage' },
  { sku: 'draggable-react-modal', text: 'Draggable React Modal' },
  { sku: 'react-modal-with-backdrop', text: 'React Modal with Backdrop' }
];

export const dividerTags = [
  { sku: 'react-divider', text: 'React Divider' },
  { sku: 'react-horizontal-divider', text: 'React Horizontal Divider' },
  { sku: 'react-vertical-divider', text: 'React Vertical Divider' },
  { sku: 'react-ui-divider', text: 'React UI Divider' },
  { sku: 'react-divider-component', text: 'React Divider Component' },
  { sku: 'react-divider-example', text: 'Example of React Divider' },
  { sku: 'custom-react-divider', text: 'Custom React Divider' },
  { sku: 'responsive-react-divider', text: 'Responsive React Divider' },
  { sku: 'react-divider-with-text', text: 'React Divider with Text' },
  { sku: 'react-divider-styling', text: 'Styling React Divider' },
  { sku: 'react-divider-animation', text: 'React Divider Animation' },
  { sku: 'react-divider-hooks', text: 'React Divider with Hooks' },
  { sku: 'react-simple-divider', text: 'Simple React Divider' },
  { sku: 'react-thin-divider', text: 'Thin React Divider' },
  { sku: 'react-customizable-divider', text: 'Customizable React Divider' },
  { sku: 'react-divider-usage', text: 'Usage of React Divider' },
  { sku: 'react-divider-best-practices', text: 'Best Practices for React Divider' },
  { sku: 'react-divider-in-layout', text: 'Using React Divider in Layout' },
  { sku: 'react-divider-library', text: 'React Divider Library' },
  { sku: 'react-divider-with-icons', text: 'React Divider with Icons' },
  { sku: 'react-divider-design', text: 'Designing a React Divider' }
];

export const inputTags = [
  { sku: 'react-input', text: 'React Input' },
  { sku: 'input-component-react', text: 'Input Component for React' },
  { sku: 'react-text-input', text: 'React Text Input' },
  { sku: 'react-number-input', text: 'React Number Input' },
  { sku: 'react-password-input', text: 'React Password Input' },
  { sku: 'react-textarea', text: 'React Textarea' },
  { sku: 'controlled-input-react', text: 'Controlled Input in React' },
  { sku: 'uncontrolled-input-react', text: 'Uncontrolled Input in React' },
  { sku: 'react-input-validation', text: 'Input Validation in React' },
  { sku: 'react-input-styling', text: 'Styling Input Component in React' },
  { sku: 'react-custom-input', text: 'Custom Input in React' },
  { sku: 'react-input-with-hooks', text: 'Input with Hooks in React' },
  { sku: 'input-label-component-react', text: 'Input Label Component for React' },
  { sku: 'react-search-input', text: 'React Search Input' },
  { sku: 'react-autocomplete-input', text: 'React Autocomplete Input' },
  { sku: 'react-input-group', text: 'React Input Group' },
  { sku: 'react-input-placeholder', text: 'Input Placeholder in React' },
  { sku: 'responsive-input-react', text: 'Responsive Input Component in React' },
  { sku: 'input-component-accessibility', text: 'Input Component Accessibility in React' },
  { sku: 'react-input-example', text: 'Example Input Component in React' }
];

export const infiniteScrollTags = [
  { sku: 'react-infinite-scroll', text: 'React Infinite Scroll' },
  { sku: 'infinite-scroll-component', text: 'Infinite Scroll Component' },
  { sku: 'react-infinite-scrolling', text: 'React Infinite Scrolling' },
  { sku: 'react-virtual-scroll', text: 'React Virtual Scroll' },
  { sku: 'load-more-on-scroll', text: 'Load More on Scroll' },
  { sku: 'infinite-scroll-library', text: 'Infinite Scroll Library' },
  { sku: 'react-infinite-scroll-hook', text: 'React Infinite Scroll Hook' },
  { sku: 'infinite-scroll-example', text: 'Infinite Scroll Example' },
  { sku: 'lazy-load-in-react', text: 'Lazy Load in React' },
  { sku: 'react-scroll-pagination', text: 'React Scroll Pagination' },
  { sku: 'infinite-scroll-implementation', text: 'Infinite Scroll Implementation' },
  { sku: 'react-infinite-list', text: 'React Infinite List' },
  { sku: 'scrolling-content-react', text: 'Scrolling Content in React' },
  { sku: 'custom-infinite-scroll', text: 'Custom Infinite Scroll' },
  { sku: 'react-infinite-scroll-component', text: 'React Infinite Scroll Component' },
  { sku: 'optimized-infinite-scroll', text: 'Optimized Infinite Scroll' },
  { sku: 'react-infinite-load-more', text: 'React Infinite Load More' },
  { sku: 'infinite-scrolling-list', text: 'Infinite Scrolling List' },
  { sku: 'react-infinite-scroll-hooks', text: 'React Infinite Scroll Hooks' },
  { sku: 'infinite-scroll-performance', text: 'Infinite Scroll Performance' },
  { sku: 'react-infinite-scrolling-example', text: 'React Infinite Scrolling Example' }
];

export const linkTags = [
  { sku: 'react-link-component', text: 'React Link Component' },
  { sku: 'link-component-react-router', text: 'Link Component for React Router' },
  { sku: 'react-navigation-link', text: 'React Navigation Link' },
  { sku: 'react-linking-library', text: 'React Linking Library' },
  { sku: 'react-anchor-tag', text: 'React Anchor Tag' },
  { sku: 'custom-link-component-react', text: 'Custom Link Component in React' },
  { sku: 'dynamic-link-component', text: 'Dynamic Link Component in React' },
  { sku: 'responsive-link-component', text: 'Responsive Link Component' },
  { sku: 'link-styling-react', text: 'Link Styling in React' },
  { sku: 'react-link-example', text: 'Example of Link in React' },
  { sku: 'react-link-props', text: 'React Link Component Props' },
  { sku: 'external-link-react', text: 'External Link in React' },
  { sku: 'internal-link-react', text: 'Internal Link in React' },
  { sku: 'react-link-redirect', text: 'React Link Redirect' },
  { sku: 'react-linking-components', text: 'Linking Components in React' },
  { sku: 'accessibility-react-link', text: 'Accessibility for React Link' },
  { sku: 'link-component-usage', text: 'Usage of Link Component in React' },
  { sku: 'react-link-state', text: 'React Link State Management' },
  { sku: 'link-navigation-react', text: 'Navigation Link in React' },
  { sku: 'link-component-implementation', text: 'Implementing Link Component in React' }
];

export const linkifyTags = [
  { sku: 'react-linkify', text: 'React Linkify' },
  { sku: 'linkify-component', text: 'Linkify Component' },
  { sku: 'linkify-react', text: 'Linkify in React' },
  { sku: 'react-url-linkify', text: 'React URL Linkify' },
  { sku: 'linkify-text', text: 'Linkify Text' },
  { sku: 'react-autolinker', text: 'React Autolinker' },
  { sku: 'linkify-js-react', text: 'Linkify.js for React' },
  { sku: 'url-linkify-react', text: 'URL Linkify Component' },
  { sku: 'linkify-html', text: 'Linkify HTML' },
  { sku: 'react-linkify-plugin', text: 'React Linkify Plugin' },
  { sku: 'linkify-url-component', text: 'Linkify URL Component' },
  { sku: 'linkify-library-react', text: 'Linkify Library for React' },
  { sku: 'react-linkify-example', text: 'Example of Linkify in React' },
  { sku: 'linkify-mentions-react', text: 'Linkify Mentions in React' },
  { sku: 'react-linkify-customization', text: 'Customizing Linkify in React' },
  { sku: 'linkify-component-usage', text: 'Using Linkify Component' },
  { sku: 'react-linkify-accessibility', text: 'React Linkify Accessibility' },
  { sku: 'dynamic-linkify-react', text: 'Dynamic Linkify Component' },
  { sku: 'react-linkify-functionality', text: 'Linkify Functionality in React' },
  { sku: 'react-linkify-urls', text: 'Linkify URLs in React' }
];

export const loadingTags = [
  { sku: 'react-loading', text: 'React Loading' },
  { sku: 'loading-component-react', text: 'Loading Component for React' },
  { sku: 'react-spinner', text: 'React Spinner' },
  { sku: 'react-loader', text: 'React Loader' },
  { sku: 'react-loading-indicator', text: 'React Loading Indicator' },
  { sku: 'custom-loading-react', text: 'Custom Loading in React' },
  { sku: 'react-loading-animation', text: 'React Loading Animation' },
  { sku: 'asynchronous-loading-react', text: 'Asynchronous Loading in React' },
  { sku: 'react-loading-placeholder', text: 'React Loading Placeholder' },
  { sku: 'progress-indicator-react', text: 'Progress Indicator in React' },
  { sku: 'react-loading-overlay', text: 'React Loading Overlay' },
  { sku: 'react-skeleton-loader', text: 'React Skeleton Loader' },
  { sku: 'loading-state-react', text: 'Loading State in React' },
  { sku: 'react-spinner-component', text: 'React Spinner Component' },
  { sku: 'react-loading-ui', text: 'Loading UI for React' },
  { sku: 'lightweight-react-loader', text: 'Lightweight React Loader' },
  { sku: 'responsive-loading-react', text: 'Responsive Loading in React' },
  { sku: 'react-circular-loader', text: 'React Circular Loader' },
  { sku: 'react-linear-loader', text: 'React Linear Loader' },
  { sku: 'react-progress-bar', text: 'React Progress Bar' }
];

export const menuTags = [
  { sku: 'react-dropdown-menu', text: 'React Dropdown Menu' },
  { sku: 'react-collapsible-dropdown', text: 'React Collapsible Dropdown' },
  { sku: 'react-dropdown-component', text: 'React Dropdown Component' },
  { sku: 'dropdown-ui-react', text: 'Dropdown UI in React' },
  { sku: 'react-select-dropdown', text: 'React Select Dropdown' },
  { sku: 'dropdown-navigation-react', text: 'Dropdown Navigation in React' },
  { sku: 'react-dropdown-list', text: 'React Dropdown List' },
  { sku: 'custom-react-dropdown', text: 'Custom React Dropdown' },
  { sku: 'react-multilevel-dropdown', text: 'React Multilevel Dropdown' },
  { sku: 'responsive-dropdown-react', text: 'Responsive Dropdown in React' },
  { sku: 'react-dropdown-animation', text: 'Dropdown Animation in React' },
  { sku: 'react-dropdown-with-hooks', text: 'React Dropdown with Hooks' },
  { sku: 'dropdown-accessibility-react', text: 'Dropdown Accessibility in React' },
  { sku: 'react-dropdown-examples', text: 'Example Dropdown in React' },
  { sku: 'react-dropdown-library', text: 'React Dropdown Library' },
  { sku: 'react-dropdown-styling', text: 'Styling React Dropdown' },
  { sku: 'dropdown-toggle-react', text: 'Dropdown Toggle in React' },
  { sku: 'react-filterable-dropdown', text: 'React Filterable Dropdown' },
  { sku: 'simple-react-dropdown', text: 'Simple React Dropdown' },
  { sku: 'advanced-react-dropdown', text: 'Advanced React Dropdown' }
];

export const popoverTags = [
  { sku: 'react-popover-menu', text: 'React Popover Menu' },
  { sku: 'react-dropdown-menu', text: 'React Dropdown Menu' },
  { sku: 'react-tooltip-menu', text: 'React Tooltip Menu' },
  { sku: 'react-context-menu', text: 'React Context Menu' },
  { sku: 'react-clickable-popover', text: 'React Clickable Popover' },
  { sku: 'react-menu-component', text: 'React Menu Component' },
  { sku: 'react-popover-component', text: 'React Popover Component' },
  { sku: 'custom-react-popover', text: 'Custom React Popover' },
  { sku: 'react-modal-menu', text: 'React Modal Menu' },
  { sku: 'react-popover-hooks', text: 'React Popover with Hooks' },
  { sku: 'responsive-react-popover', text: 'Responsive React Popover' },
  { sku: 'react-popover-animation', text: 'Popover Animation in React' },
  { sku: 'react-popover-accessibility', text: 'React Popover Accessibility' },
  { sku: 'react-menu-toggle', text: 'React Menu Toggle' },
  { sku: 'advanced-react-popover', text: 'Advanced React Popover' },
  { sku: 'react-popover-with-options', text: 'React Popover with Options' },
  { sku: 'react-conditional-popover', text: 'Conditional React Popover' },
  { sku: 'react-popover-library', text: 'React Popover Library' },
  { sku: 'react-popover-example', text: 'Example Popover in React' },
  { sku: 'react-popup-menu', text: 'React Popup Menu' }
];

export const progressTags = [
  { sku: 'react-progress-bar', text: 'React Progress Bar' },
  { sku: 'progress-indicator-react', text: 'Progress Indicator in React' },
  { sku: 'react-loading-progress', text: 'React Loading Progress' },
  { sku: 'progress-component-react', text: 'Progress Component for React' },
  { sku: 'react-custom-progress', text: 'Custom Progress in React' },
  { sku: 'react-progress-circle', text: 'React Progress Circle' },
  { sku: 'animated-progress-react', text: 'Animated Progress Component' },
  { sku: 'react-linear-progress', text: 'React Linear Progress' },
  { sku: 'react-determinate-progress', text: 'Determinate Progress in React' },
  { sku: 'react-indeterminate-progress', text: 'Indeterminate Progress in React' },
  { sku: 'react-progress-loader', text: 'React Progress Loader' },
  { sku: 'responsive-progress-bar', text: 'Responsive Progress Bar in React' },
  { sku: 'progress-tracker-react', text: 'Progress Tracker in React' },
  { sku: 'react-progress-with-steps', text: 'Progress with Steps in React' },
  { sku: 'customizable-progress-bar', text: 'Customizable Progress Bar' },
  { sku: 'progress-animation-react', text: 'Progress Animation in React' },
  { sku: 'progress-tooltip-react', text: 'Progress Tooltip in React' },
  { sku: 'multi-step-progress-react', text: 'Multi-Step Progress in React' },
  { sku: 'progress-component-library', text: 'Progress Component Library' },
  { sku: 'react-progress-api', text: 'React Progress API' }
];

export const radioGroupTags = [
  { sku: 'react-radio-group', text: 'React Radio Group' },
  { sku: 'radio-button-group-react', text: 'Radio Button Group in React' },
  { sku: 'react-radio-group-component', text: 'React Radio Group Component' },
  { sku: 'radio-group-ui', text: 'Radio Group UI Component' },
  { sku: 'react-radio-buttons', text: 'React Radio Buttons' },
  { sku: 'custom-react-radio-group', text: 'Custom React Radio Group' },
  { sku: 'react-radio-group-example', text: 'Example of React Radio Group' },
  { sku: 'react-radio-group-hooks', text: 'React Radio Group with Hooks' },
  { sku: 'accessible-radio-group-react', text: 'Accessible Radio Group in React' },
  { sku: 'radio-group-validation-react', text: 'Validation for Radio Group in React' },
  { sku: 'dynamic-radio-group-react', text: 'Dynamic Radio Group in React' },
  { sku: 'react-radio-group-styling', text: 'Styling React Radio Group' },
  { sku: 'react-radio-selection', text: 'React Radio Selection' },
  { sku: 'react-controlled-radio-group', text: 'Controlled Radio Group in React' },
  { sku: 'radio-group-component', text: 'Radio Group Component' },
  { sku: 'react-radio-group-library', text: 'React Radio Group Library' },
  { sku: 'simple-react-radio-group', text: 'Simple React Radio Group' },
  { sku: 'react-radio-group-api', text: 'API for React Radio Group' },
  { sku: 'react-radio-group-usage', text: 'Usage of React Radio Group' },
  { sku: 'responsive-radio-group-react', text: 'Responsive Radio Group in React' }
];

export const selectTags = [
  { sku: 'react-select', text: 'React Select' },
  { sku: 'react-combobox', text: 'React ComboBox' },
  { sku: 'react-dropdown', text: 'React Dropdown' },
  { sku: 'react-multi-select', text: 'React Multi-Select' },
  { sku: 'react-single-select', text: 'React Single Select' },
  { sku: 'react-select-component', text: 'React Select Component' },
  { sku: 'custom-react-select', text: 'Custom React Select' },
  { sku: 'react-select-hooks', text: 'React Select with Hooks' },
  { sku: 'accessible-react-select', text: 'Accessible React Select' },
  { sku: 'react-select-styling', text: 'Styling React Select' },
  { sku: 'async-react-select', text: 'Async React Select' },
  { sku: 'react-combobox-component', text: 'React ComboBox Component' },
  { sku: 'react-select-options', text: 'React Select Options' },
  { sku: 'react-select-search', text: 'React Select Search' },
  { sku: 'react-select-api', text: 'React Select with API' },
  { sku: 'responsive-react-select', text: 'Responsive React Select' },
  { sku: 'react-select-example', text: 'Example of React Select' },
  { sku: 'react-custom-combobox', text: 'Custom ComboBox in React' },
  { sku: 'react-select-input', text: 'React Select Input' },
  { sku: 'dynamic-react-select', text: 'Dynamic React Select' }
];

export const sheetTags = [
  { sku: 'react-sheet', text: 'React Sheet' },
  { sku: 'react-sheet-component', text: 'React Sheet Component' },
  { sku: 'react-sheet-ui', text: 'React Sheet UI' },
  { sku: 'react-dynamic-sheet', text: 'Dynamic React Sheet' },
  { sku: 'react-modal-sheet', text: 'React Modal Sheet' },
  { sku: 'react-sheet-menu', text: 'React Sheet Menu' },
  { sku: 'react-sheet-overlay', text: 'React Sheet Overlay' },
  { sku: 'collapsible-sheet-react', text: 'Collapsible Sheet in React' },
  { sku: 'react-bottom-sheet', text: 'React Bottom Sheet' },
  { sku: 'react-sheet-design', text: 'React Sheet Design' },
  { sku: 'responsive-react-sheet', text: 'Responsive React Sheet' },
  { sku: 'react-sheet-animation', text: 'React Sheet Animation' },
  { sku: 'react-sheet-hooks', text: 'React Sheet with Hooks' },
  { sku: 'custom-react-sheet', text: 'Custom React Sheet' },
  { sku: 'react-sheet-example', text: 'Example React Sheet' },
  { sku: 'sheet-component-react', text: 'Sheet Component for React' },
  { sku: 'react-advanced-sheet', text: 'Advanced React Sheet' },
  { sku: 'react-data-sheet', text: 'React Data Sheet' },
  { sku: 'react-sheet-templates', text: 'React Sheet Templates' },
  { sku: 'interactive-react-sheet', text: 'Interactive React Sheet' },
  { sku: 'react-sheet-accessibility', text: 'React Sheet Accessibility' }
];

export const skeletonTags = [
  { sku: 'react-skeleton-loader', text: 'React Skeleton Loader' },
  { sku: 'skeleton-loading-component', text: 'Skeleton Loading Component' },
  { sku: 'react-loading-skeleton', text: 'React Loading Skeleton' },
  { sku: 'skeleton-placeholder', text: 'Skeleton Placeholder' },
  { sku: 'react-placeholder-loader', text: 'React Placeholder Loader' },
  { sku: 'skeleton-screen-loader', text: 'Skeleton Screen Loader' },
  { sku: 'react-skeleton-ui', text: 'React Skeleton UI' },
  { sku: 'skeleton-loader-animation', text: 'Skeleton Loader Animation' },
  { sku: 'dynamic-skeleton-loader', text: 'Dynamic Skeleton Loader' },
  { sku: 'skeleton-loader-implementation', text: 'Skeleton Loader Implementation' },
  { sku: 'custom-skeleton-loader', text: 'Custom Skeleton Loader' },
  { sku: 'react-ui-skeleton-loader', text: 'React UI Skeleton Loader' },
  { sku: 'skeleton-loading-effect', text: 'Skeleton Loading Effect' },
  { sku: 'responsive-skeleton-loader', text: 'Responsive Skeleton Loader' },
  { sku: 'skeleton-loader-example', text: 'Skeleton Loader Example' },
  { sku: 'react-skeleton-loader-library', text: 'React Skeleton Loader Library' },
  { sku: 'css-skeleton-loader', text: 'CSS Skeleton Loader' },
  { sku: 'lightweight-skeleton-loader', text: 'Lightweight Skeleton Loader' },
  { sku: 'skeleton-loader-best-practices', text: 'Skeleton Loader Best Practices' },
  { sku: 'skeleton-loader-for-react', text: 'Skeleton Loader for React' }
];

export const sliderTags = [
  { sku: 'react-slider', text: 'React Slider' },
  { sku: 'react-carousel', text: 'React Carousel' },
  { sku: 'react-image-slider', text: 'React Image Slider' },
  { sku: 'responsive-slider-react', text: 'Responsive Slider in React' },
  { sku: 'react-range-slider', text: 'React Range Slider' },
  { sku: 'react-slider-component', text: 'React Slider Component' },
  { sku: 'react-slider-animation', text: 'Slider Animation in React' },
  { sku: 'react-multi-item-slider', text: 'React Multi-Item Slider' },
  { sku: 'react-autoplay-slider', text: 'React Autoplay Slider' },
  { sku: 'react-touch-slider', text: 'React Touch Slider' },
  { sku: 'react-slider-hooks', text: 'React Slider with Hooks' },
  { sku: 'custom-react-slider', text: 'Custom React Slider' },
  { sku: 'react-slider-example', text: 'Example Slider in React' },
  { sku: 'react-slider-ui', text: 'React Slider UI' },
  { sku: 'slider-with-thumbnails-react', text: 'Slider with Thumbnails in React' },
  { sku: 'react-fade-slider', text: 'React Fade Slider' },
  { sku: 'react-image-carousel', text: 'React Image Carousel' },
  { sku: 'react-content-slider', text: 'React Content Slider' },
  { sku: 'react-vertical-slider', text: 'React Vertical Slider' },
  { sku: 'react-slider-design', text: 'Slider Design in React' }
];

export const switchTags = [
  { sku: 'react-switch', text: 'React Switch' },
  { sku: 'toggle-switch-component', text: 'Toggle Switch Component' },
  { sku: 'react-toggle-button', text: 'React Toggle Button' },
  { sku: 'react-ui-switch', text: 'React UI Switch' },
  { sku: 'react-checkbox-switch', text: 'React Checkbox Switch' },
  { sku: 'react-switch-component', text: 'React Switch Component' },
  { sku: 'custom-react-switch', text: 'Custom React Switch' },
  { sku: 'react-on-off-switch', text: 'React On/Off Switch' },
  { sku: 'animated-react-switch', text: 'Animated React Switch' },
  { sku: 'react-switch-example', text: 'Example of React Switch' },
  { sku: 'react-accessible-switch', text: 'Accessible React Switch' },
  { sku: 'styled-react-switch', text: 'Styled React Switch' },
  { sku: 'react-switch-hook', text: 'React Switch with Hooks' },
  { sku: 'multi-state-react-switch', text: 'Multi-State React Switch' },
  { sku: 'react-switch-library', text: 'React Switch Library' },
  { sku: 'responsive-react-switch', text: 'Responsive React Switch' },
  { sku: 'react-switch-with-label', text: 'React Switch with Label' },
  { sku: 'toggle-switch-animation', text: 'Toggle Switch Animation' },
  { sku: 'react-switch-demo', text: 'React Switch Demo' },
  { sku: 'react-switch-integration', text: 'React Switch Integration' }
];

export const tableTags = [
  { sku: 'react-table', text: 'React Table' },
  { sku: 'react-data-table', text: 'React Data Table' },
  { sku: 'react-table-component', text: 'React Table Component' },
  { sku: 'react-table-hooks', text: 'React Table with Hooks' },
  { sku: 'custom-react-table', text: 'Custom React Table' },
  { sku: 'react-table-example', text: 'Example React Table' },
  { sku: 'react-advanced-table', text: 'Advanced React Table' },
  { sku: 'react-table-pagination', text: 'React Table Pagination' },
  { sku: 'react-table-filter', text: 'React Table Filter' },
  { sku: 'responsive-react-table', text: 'Responsive React Table' },
  { sku: 'react-table-sorting', text: 'React Table Sorting' },
  { sku: 'react-virtualized-table', text: 'React Virtualized Table' },
  { sku: 'react-table-CRUD', text: 'React Table with CRUD Operations' },
  { sku: 'react-inline-edit-table', text: 'React Inline Edit Table' },
  { sku: 'react-table-optimization', text: 'Optimized React Table' },
  { sku: 'react-table-column-resizing', text: 'React Table Column Resizing' },
  { sku: 'react-table-selection', text: 'React Table Row Selection' },
  { sku: 'react-customizable-table', text: 'Customizable React Table' },
  { sku: 'react-table-plugins', text: 'React Table Plugins' },
  { sku: 'react-table-accessibility', text: 'React Table Accessibility' }
];

export const tabsTags = [
  { sku: 'react-tabs', text: 'React Tabs' },
  { sku: 'react-tab-component', text: 'React Tab Component' },
  { sku: 'react-ui-tabs', text: 'React UI Tabs' },
  { sku: 'react-tabs-example', text: 'Example React Tabs' },
  { sku: 'react-horizontal-tabs', text: 'Horizontal Tabs in React' },
  { sku: 'react-vertical-tabs', text: 'Vertical Tabs in React' },
  { sku: 'react-custom-tabs', text: 'Custom Tabs in React' },
  { sku: 'react-tab-navigation', text: 'Tab Navigation in React' },
  { sku: 'react-tabs-library', text: 'React Tabs Library' },
  { sku: 'react-tabs-hooks', text: 'React Tabs with Hooks' },
  { sku: 'react-tabs-animation', text: 'Tabs Animation in React' },
  { sku: 'react-tabs-accessibility', text: 'React Tabs Accessibility' },
  { sku: 'react-multi-tabs', text: 'React Multi-Level Tabs' },
  { sku: 'react-tabs-styling', text: 'Styling React Tabs' },
  { sku: 'responsive-react-tabs', text: 'Responsive React Tabs' },
  { sku: 'react-tabs-usage', text: 'Usage of React Tabs' },
  { sku: 'react-tabs-with-icons', text: 'React Tabs with Icons' },
  { sku: 'react-tabs-design', text: 'Designing React Tabs' },
  { sku: 'react-tabs-demos', text: 'React Tabs Demos' },
  { sku: 'react-tabs-customization', text: 'Customizing React Tabs' }
];

export const textareaTags = [
  { sku: 'react-textarea', text: 'React Textarea' },
  { sku: 'react-multiline-input', text: 'React Multiline Input' },
  { sku: 'react-textarea-component', text: 'React Textarea Component' },
  { sku: 'react-autoresizing-textarea', text: 'React Autoresizing Textarea' },
  { sku: 'react-custom-textarea', text: 'Custom Textarea in React' },
  { sku: 'react-textarea-hooks', text: 'React Textarea with Hooks' },
  { sku: 'controlled-textarea-react', text: 'Controlled Textarea in React' },
  { sku: 'uncontrolled-textarea-react', text: 'Uncontrolled Textarea in React' },
  { sku: 'react-textarea-validation', text: 'Textarea Validation in React' },
  { sku: 'react-textarea-autocomplete', text: 'React Textarea Autocomplete' },
  { sku: 'responsive-react-textarea', text: 'Responsive React Textarea' },
  { sku: 'react-textarea-example', text: 'Example Textarea in React' },
  { sku: 'react-textarea-styling', text: 'Styling React Textarea' },
  { sku: 'react-textarea-placeholder', text: 'Textarea Placeholder in React' },
  { sku: 'react-textarea-resize', text: 'Resizable Textarea in React' },
  { sku: 'react-textarea-aria', text: 'ARIA Accessibility for React Textarea' },
  { sku: 'react-character-count-textarea', text: 'Character Count in React Textarea' },
  { sku: 'react-textarea-onchange', text: 'OnChange Event for React Textarea' },
  { sku: 'react-textarea-default-value', text: 'Default Value in React Textarea' },
  { sku: 'react-textarea-limited-input', text: 'Limited Input Textarea in React' },
  { sku: 'react-textarea-error-message', text: 'Error Message for React Textarea' }
];

export const toastTags = [
  { sku: 'react-toast', text: 'React Toast' },
  { sku: 'react-notifications', text: 'React Notifications' },
  { sku: 'react-toast-component', text: 'React Toast Component' },
  { sku: 'react-toast-notifications', text: 'React Toast Notifications' },
  { sku: 'react-toast-message', text: 'React Toast Message' },
  { sku: 'react-toast-library', text: 'React Toast Library' },
  { sku: 'react-alert', text: 'React Alert' },
  { sku: 'toast-notifications-react', text: 'Toast Notifications in React' },
  { sku: 'custom-react-toast', text: 'Custom React Toast' },
  { sku: 'react-toast-example', text: 'Example of React Toast' },
  { sku: 'react-toast-styling', text: 'Styling React Toast' },
  { sku: 'react-auto-dismiss-toast', text: 'Auto-Dismiss React Toast' },
  { sku: 'react-toast-hooks', text: 'React Toast with Hooks' },
  { sku: 'react-toast-container', text: 'React Toast Container' },
  { sku: 'react-toast-animation', text: 'React Toast Animation' },
  { sku: 'react-toast-position', text: 'React Toast Positioning' },
  { sku: 'react-multi-toast', text: 'Multi-Toast in React' },
  { sku: 'responsive-react-toast', text: 'Responsive React Toast' },
  { sku: 'react-toast-customization', text: 'React Toast Customization' },
  { sku: 'react-toast-integration', text: 'Integrating React Toast' }
];

export const tooltipTags = [
  { sku: 'react-tooltip', text: 'React Tooltip' },
  { sku: 'react-tooltip-component', text: 'React Tooltip Component' },
  { sku: 'react-custom-tooltip', text: 'Custom Tooltip in React' },
  { sku: 'react-tooltip-library', text: 'React Tooltip Library' },
  { sku: 'react-tooltip-example', text: 'Example Tooltip in React' },
  { sku: 'react-tooltip-hooks', text: 'React Tooltip with Hooks' },
  { sku: 'react-tooltip-positioning', text: 'Tooltip Positioning in React' },
  { sku: 'react-tooltip-accessibility', text: 'React Tooltip Accessibility' },
  { sku: 'react-tooltip-animation', text: 'Tooltip Animation in React' },
  { sku: 'react-tooltip-css', text: 'Styling React Tooltip' },
  { sku: 'react-tooltip-trigger', text: 'Tooltip Trigger Options in React' },
  { sku: 'react-tooltip-api', text: 'React Tooltip API' },
  { sku: 'react-tooltip-examples', text: 'React Tooltip Usage Examples' },
  { sku: 'react-hover-tooltip', text: 'Hover Tooltip in React' },
  { sku: 'react-click-tooltip', text: 'Click Tooltip in React' },
  { sku: 'react-tooltip-position', text: 'React Tooltip Position' },
  { sku: 'responsive-react-tooltip', text: 'Responsive React Tooltip' },
  { sku: 'react-tooltip-tips', text: 'React Tooltip Tips' },
  { sku: 'react-tooltip-interaction', text: 'Tooltip Interaction in React' },
  { sku: 'react-tooltip-advanced', text: 'Advanced React Tooltip' },
  { sku: 'react-tooltip-implementation', text: 'Implementing Tooltip in React' }
];

export const seoMasterTags = [
  { sku: 'sitemap-generator', text: 'Sitemap Generator' },
  { sku: 'xml-sitemap-generator', text: 'XML Sitemap Generator' },
  { sku: 'sitemap-creator', text: 'Sitemap Creator' },
  { sku: 'sitemap-builder', text: 'Sitemap Builder' },
  { sku: 'automated-sitemap-generator', text: 'Automated Sitemap Generator' },
  { sku: 'website-sitemap-generator', text: 'Website Sitemap Generator' },
  { sku: 'sitemap-tool', text: 'Sitemap Tool' },
  { sku: 'dynamic-sitemap-generator', text: 'Dynamic Sitemap Generator' },
  { sku: 'sitemap-generator-for-seo', text: 'Sitemap Generator for SEO' },
  { sku: 'sitemap-generator-online', text: 'Online Sitemap Generator' },
  { sku: 'sitemap-generator-plugin', text: 'Sitemap Generator Plugin' },
  { sku: 'sitemap-generator-xml', text: 'XML Sitemap Generator Tool' },
  { sku: 'easy-sitemap-generator', text: 'Easy Sitemap Generator' },
  { sku: 'sitemap-generator-free', text: 'Free Sitemap Generator' },
  { sku: 'custom-sitemap-generator', text: 'Custom Sitemap Generator' },
  { sku: 'sitemap-generator-for-wordpress', text: 'Sitemap Generator for WordPress' },
  { sku: 'responsive-sitemap-generator', text: 'Responsive Sitemap Generator' },
  { sku: 'sitemap-xml-creator', text: 'Sitemap XML Creator' },
  { sku: 'simple-sitemap-generator', text: 'Simple Sitemap Generator' },
  { sku: 'sitemap-scraper', text: 'Sitemap Scraper' },
  // Robots.txt Generator and Validator
  { sku: 'robots-txt-generator', text: 'Robots.txt Generator' },
  { sku: 'robots-txt-validator', text: 'Robots.txt Validator' },
  { sku: 'robots-txt-creator', text: 'Robots.txt Creator' },
  { sku: 'robots-txt-file-generator', text: 'Robots.txt File Generator' },
  { sku: 'robots-txt-online-tool', text: 'Online Robots.txt Tool' },
  { sku: 'robots-txt-generator-for-seo', text: 'Robots.txt Generator for SEO' },
  { sku: 'robots-txt-checker', text: 'Robots.txt Checker' },
  { sku: 'robots-txt-editor', text: 'Robots.txt Editor' },
  { sku: 'robots-txt-file-creator', text: 'Robots.txt File Creator' },
  { sku: 'robots-txt-test-tool', text: 'Robots.txt Test Tool' },
  { sku: 'robots-txt-file-validator', text: 'Robots.txt File Validator' },
  // Meta Tags Generator and Validator
  { sku: 'meta-tags-generator', text: 'Meta Tags Generator' },
  { sku: 'meta-tags-validator', text: 'Meta Tags Validator' },
  { sku: 'meta-description-generator', text: 'Meta Description Generator' },
  { sku: 'meta-tags-tool', text: 'Meta Tags Tool' },
  { sku: 'seo-meta-tags-generator', text: 'SEO Meta Tags Generator' },
  { sku: 'meta-title-generator', text: 'Meta Title Generator' },
  { sku: 'meta-description-editor', text: 'Meta Description Editor' },
  { sku: 'meta-keywords-generator', text: 'Meta Keywords Generator' },
  { sku: 'open-graph-meta-tags-generator', text: 'Open Graph Meta Tags Generator' },
  { sku: 'meta-tags-analyzer', text: 'Meta Tags Analyzer' },
  { sku: 'meta-tags-checker', text: 'Meta Tags Checker' },
  // Favicon and Image Assets Generator
  { sku: 'favicon-generator', text: 'Favicon Generator' },
  { sku: 'image-assets-generator', text: 'Image Assets Generator' },
  { sku: 'favicon-creator', text: 'Favicon Creator' },
  { sku: 'favicon-maker', text: 'Favicon Maker' },
  { sku: 'website-favicon-generator', text: 'Website Favicon Generator' },
  { sku: 'favicon-generator-online', text: 'Online Favicon Generator' },
  { sku: 'image-optimizer', text: 'Image Optimizer' },
  { sku: 'responsive-image-generator', text: 'Responsive Image Generator' },
  { sku: 'favicon-converter', text: 'Favicon Converter' },
  { sku: 'web-assets-generator', text: 'Web Assets Generator' },
  { sku: 'favicon-resizer', text: 'Favicon Resizer' },
  { sku: 'web-icons-generator', text: 'Web Icons Generator' },
  { sku: 'website-assets-creator', text: 'Website Assets Creator' }
];
